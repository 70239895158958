// Disclaimer.js
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const Disclaimer = () => {
  useEffect(() => {
    // Track page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Apply styles directly to the body for scrolling
    document.body.style.overflowY = 'auto';
    document.body.style.maxHeight = '100vh';

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.overflowY = 'initial';
      document.body.style.maxHeight = 'initial';
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="disclaimer-container" style={{ marginLeft: '190px', marginRight: '200px' }}>
    <h1>Disclaimer</h1>

    <p>De informatie op de site en in de App wordt door mij met ultieme zorg en
    aandacht samengesteld, en is natuurlijk met name aangedragen en tot stand
    gekomen door de bijdrages van de enthousiaste schaatsliefhebbers. Toch is
    het mogelijk dat de informatie en de punten onvolledig of onjuist is. De
    informatie wordt geregeld aangepast en gewijzigd. Ik behoud me het recht
    voor om eventuele wijzigingen met onmiddellijke ingang  en zonder
    kennisgeving door te voeren.</p>

    <p>Controleer altijd zelf ter plekke het ijs alvorens je gaat schaatsen. Ik
    ben niet verantwoordelijk voor de schaatskwaliteit. Verder ben ik niet
    verantwoordelijk en aansprakelijk voor schade eventueel opgelopen door
    foutieve informatie doorgestuurd door gebruikers van de site en de App.</p>

    <h3>Enkele tips voor veilig schaatsen:</h3>
      <ul>
        <li>Ga nooit alleen op natuurijs.</li>
        <li>Kijk uit bij bruggen, planten en takken.</li>
        <li>Schaats niet op natuurijs in het donker.</li>
        <li>Bij sneeuw op het ijs niet schaatsen!</li>
        <li>Kleed je goed tegen de kou en wind.</li>
        <li>Neem een ijsprikker mee voor het geval je in een wak rijdt.</li>
        <li>Controleer de ijsdikte, natuurijs dunner dan 8 cm is onveilig.</li>
    </ul>
    <p></p><p><p></p></p>

  </div>
  );
};

export default Disclaimer;

