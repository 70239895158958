import React, { useState, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga'; // Import ReactGA library

const BannerRotator = () => {
  const banners = useMemo (() => [
    { url: `${process.env.PUBLIC_URL}/groen-leeg.png`, link: 'mailto:kaart@ekkel.com' },
    { url: `${process.env.PUBLIC_URL}/rood-leeg.png`, link: 'mailto:kaart@ekkel.com' },
  ], []);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const rotateBanners = () => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    };

    const rotationInterval = setInterval(rotateBanners, 5000);

    return () => clearInterval(rotationInterval);
  }, [banners]);

  const handleClick = (link) => {
    window.open(link, '_blank'); // Open the link in a new tab

    // Send event to Google Analytics
    ReactGA.event({
      category: 'Banner Click', // Event category
      action: 'Click', // Event action
      label: link // Event label (the URL of the clicked banner)
    });
  };

  return (
    <div onClick={() => handleClick(banners[currentBannerIndex].link)}>
      <img src={banners[currentBannerIndex].url} alt="Banner" />
    </div>
  );
};

export default BannerRotator;