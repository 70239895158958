// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'; // Import the react-ga library
import './App.css'; // Import the CSS file for styling
import About from './About.js'; // Import the About component
import Knmi from './Knmi.js'; // Import the Knmi component
import Disclaimer from './Disclaimer.js'; // Import the Disclaimer component
import Footer from './Footer.js';
import Navigation from './Navigation.js';
import Header from './Header.js';
import BannerRotator from './BannerRotator.js';
import Map from "./Map.js";
import 'mapbox-gl/dist/mapbox-gl.css';

ReactGA.initialize('UA-3577459-1'); // Initialize with your tracking ID

const OverlayContent = () => (
  <div className="overlay-content">
    <hr/>
      <p>
        Voor het toevoegen van punten en mededelingen, download en gebruik de IJsmeester App.
        Vragen en opmerkingen sturen naar{' '}
        <a href="mailto:kaart@ekkel.com">kaart@ekkel.com</a>.
      </p>
      <h4>Legenda</h4>
      <div>
        <img
          src="./rood.jpeg"
          alt=""
          width="18"
          height="25"
          style={{ display: 'inline-block' }}
        />
        <p style={{ display: 'inline-block', fontSize: '12px' }}>Gevaarlijk, niet doen</p>
      </div>
      <div>
        <img
          src="./groen.jpeg"
          alt=""
          width="18"
          height="25"
          style={{ display: 'inline-block' }}
        />
        <p style={{ display: 'inline-block', fontSize: '12px' }}>Schaatsen kan maar kijk uit!</p>
      </div>
      <div>
        <img
          src="./groen-i.jpeg"
          alt=""
          width="18"
          height="25"
          style={{ display: 'inline-block' }}
        />
        <p style={{ display: 'inline-block', fontSize: '12px' }}>(Kunst)ijsbaan</p>
      </div>
      <h4>Sponsoren</h4>
      <BannerRotator />
  </div>
);

const Legenda = () => {
  const location = useLocation();

  // Show legenda only on the map
  if (location.pathname === '/') {
    return (
      <>
        <OverlayContent />
      </>
    );
  }

  return null;
};

const App = () => (
  <Router basename="/">
    <div className="app">
      <Header />
      <Navigation />
      <Legenda />
      <Routes>
        <Route path="/" element={<Map />} />
        <Route path="/about" element={<About />} />
        <Route path="/knmi" element={<Knmi />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
      <Footer /> {/* Render Footer outside of Routes */}
    </div>
  </Router>
);

export default App;