// Footer.js
import React from 'react';
import Navigation from './Navigation.js';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <p>&copy; 2024 Erik Ekkel. Alle rechten voorbehouden.</p>
      </div>
    </footer>
  );
};

export default Footer;
