// About.js
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import VideoPlayer from './VideoPlayer';
import './About.css'; // Import the CSS file for styling

const About = () => {
  useEffect(() => {
    // Track page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Apply styles directly to the body for scrolling
    document.body.style.overflowY = 'auto';
    document.body.style.maxHeight = '100vh';

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.overflowY = 'initial';
      document.body.style.maxHeight = 'initial';
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="about-container" style={{ marginLeft: '190px', marginRight: '200px' }}>
      <div className="content">
        <h1>Over de site</h1>

        <p>Tijdens de eerste echte natuurijs periode in de winter van 2008/2009
        (deze begon op 2 januari 2009) ging ik op het internet op zoek naar
        informatie over leuke schaatsplekken. Tot mijn stomme verbazing was er
        eigenlijk nergens iets te vinden; je moest goed zoeken in blogs en online
        nieuwsberichten om te kunnen achterhalen waar je lekker kon schaatsen.
        Blijkbaar had het begrip natuurijs het internet nog niet
        bereikt.</p>

        <p>Ik kreeg het idee om dit anders te gaan doen. Ik was niet voor niets
        een in Friesland opgegroeide, geboren Tukker, die in Brabant terecht was
        gekomen. Als het begint te vriezen dan stijgt ook bij mij de koorts.
        Echter, geen goede plek op het internet voor natuurijsplekken; dat kon
        niet. Ik begon heel simpel met het maken van een Google Map met daarop de
        plekken, die ik ondertussen al gevonden had. Toen ik zo&#8217;n 50
        plekken had besloot ik de kaart de plaatsen op mijn eigen website. Ik
        plaatste wat linkjes in de verschillende nieuwsgroepen en wachte af wat
        er ging gebeuren. Tja, vanaf dat moment kwam ik zelf nauwelijks meer aan
        schaatsen toe. De punten stroomden binnen en ik was alleen maar bezig om
        de kaart te onderhouden. Veelal in de avonduren, want ik heb ernaast ook
        nog gewoon een baan (wel ook een internet startup, maar dat is een ander
        verhaal). Dat eerste jaar haalde ik al de lokale krant en ook Omroep
        Brabant (radio) wist me te vinden. Die eerste winter had ik al meer dan
        65.000 bezoekers op mijn site.</p>


        <p>Het jaar erop, de winter van 2009/2010 begon het al hard te vriezen in
        december 2009 en ik besloot weer door te gaan met mijn hobby. Die winter
        telde zelfs drie goede ijsperiode's en het totale bezoekersaantal
        schoot omhoog naar 173.000. Gelukkig waren er nu ook liefhebbers bij die
        zelf ook met Google Maps uit de voeten konden, en ik gaf hen toegang om
        de punten zelf te gaan onderhouden. Toch kwam ik ook deze keer zelf niet
        al te veel aan schaatsen toe :-).  Ik werd geïnterviewd door Omroep
        Brabant. Dit filmpje geeft goed weer hoe ik de data een beetje actueel
        probeer te houden.</p>

        <VideoPlayer videoId="d3A8iPflufs"/>

        <p>Ook plaatste dagblad Trouw op 9 januari 2009 een mooi artikel over al mijn werk:</p>

        <p><a href="http://www.trouw.nl/tr/nl/4324/Nieuws/article/detail/1135823/2009/01/09/Amateursites-bieden-meer-zwart-ijs-dan-Schaatsbond.dhtml"  target="_blank" rel="noopener noreferrer">Amateursites bieden meer zwart ijs dan Schaatsbond</a></p>

        <p>Ondertussen had ik de #1 positie bereikt op Google als wordt gezocht op &#8220;Waar kan ik schaatsen?&#8221;</p>

        <p>De winter van 2010/2011 begon wederom spannend te worden in december
        2010. Het begon hard te vriezen en de kaart begon weer actief te worden.
        Ondertussen was mijn topografische kennis van de mooiste schaatsplekken
        van een dusdanig niveau dat ik vrij snel plekken kon toevoegen en
        aanpassen. Mijn zoon was ondertussen ook &#8216;in de zaak&#8217;
        gestapt (hij houdt niet van schaatsen!) zodat ik zelf ook wat meer tijd
        had om de ijzers onder te binden. Ondertussen verschenen er ook andere
        schaatssite&#8217;s op het internet. Natuurijs op internet was opeens
        helemaal &#8216;hot&#8217;. Ik regelde ook dat verschillende
        nieuwssite&#8217;s een &#8216;kopie&#8217; van mijn kaart konden plaatsen
        op hun eigen site. Allemaal tegen een kleine vergoeding, want ondertussen
        waren mijn hostingskosten behoorlijk opgelopen.</p>


        <p>De grote doorbraak kwam in de winter van 2011/2012. De vorstperiode van
        februari 2012 had alle kenmerken van een &#8216;elfstedenmaker&#8217; in
        zich. De punten vlogen weer op de kaart en na een uitzending
        op &#8216;NOS op 3&#8217; ging mijn site plat; de server had het begeven.
        De hele nacht ben ik in de weer geweest een extra en grotere server te
        regelen. Gelukkig is mijn hosting geregeld in Amerika, dus de helpdesk
        aldaar was &#8216;wakker&#8217; en mij gelukkig behulpzaam. </p>

        <VideoPlayer videoId="Y3tB3DfQOpk"/>

        <p>Ook in de winter van 2012/2013 was het weer raak. De vijfde winter op
        rij met natuurijs en ik had het weer erg druk. Heb gelukkig ook zelf nog
        geschaatst. Na een uitzending in het RTL4 journaal was het weer raak: de
        site lag er even uit. Deze winter voor het eerst boven de half miljoen
        bezoekers en er zaten dagen bij met meer dan 100.000 hits. Ook heb ik
        geexperimenteerd met het concept &#8220;Rayonhoofd&#8221; : mensen die
        mij helpen de punten van een bepaalde regio bij te houden. Nu maar weer
        wachten op volgende winter….</p>

        <p>De laatste jaren waren de winters een stuk minder. In 2017 nog een
        korte periode met soms heel mooi ijs. </p>

        <p>in 2019 ben ik een samenwerking aangegaan met bedrijf BLDR uit Zwolle.
        Dit bedrijf van Jeroen Huzen en Jeroen van der Meer was bereid om samen een App
        te ontwikkelen. De App heeft er voor gezorgd dat het onderhoud en management van
        de schaatskaart gemnakkelijker werd en dat gebruikers ook veel gemakkelijker 
        hun informatie konden doorgeven. </p>

        <p>In februari 2021 was het weer raak, dit keer in de corona tijd. Strenge
        vorst zorgde ervoor dat er zelfs al weer werd nagedacht over een
        mogelijke Elfsteden Tocht. In die winter hebben we eindelijk onze nieuwe IJsmeester App
        kunnen lanceren. Op iPhone en ook op Android. Dit was meteen een groot succes en we stonden zelfs
        tijdelijk op #1 in de Apple App Store download statistiek. Die winter hebben
        we erg veel PR gehad: iedereen was wel eens toe aan wat positief nieuws na al die
        corona ellende. Een mooi artikel weer in Trouw:</p>
        
        <p><a href="https://www.trouw.nl/binnenland/waar-kun-je-de-komende-dagen-schaatsen-de-ijsschatkaart-van-erik-ekkel-biedt-uitkomst~b741a83b/"  target="_blank" rel="noopener noreferrer">Waar kun je de komende dagen schaatsen?</a></p>
        
        <p>Het hoogtepunt was toch wel ons item in het NOS 8-uur Journaal:</p>

        <VideoPlayer videoId="v37cGmvrtrk"/>

        <p>Nu is het wachten op weer eens een echte winter!</p>
      
      </div>

  </div>
  );
};

export default About;

