// Header.js
import React from 'react';
import GoApple from './GoApple.js'; // Import the App Store link
import GoGoogle from './GoGoogle.js'; // Import the Play Store link


const Header = () => {
  const headerStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
    backgroundSize: 'cover', // Adjust based on your needs
    backgroundPosition: 'center', // Adjust based on your needs
    color: '#fff', // Text color on top of the image
    padding: '20px', // Adjust based on your needs
    height: '100px', // Adjust the height of the header
  };

  return (
    <header style={headerStyle}>
      {/* Your header content goes here */}
      <h2>Waar kan ik schaatsen?</h2>
      <GoApple/>
      <GoGoogle/>
    </header>
  );
};

export default Header;