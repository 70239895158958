import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
import Papa from "papaparse"; // Import PapaParse for CSV parsing
import AddInfoModal from "./AddInfoModal.js"; // Import AddInfoModal component

mapboxgl.accessToken = "pk.eyJ1IjoiZWtrZWwiLCJhIjoiMVlzcXRkcyJ9.b_oschxEW2jP2vryLV372A"

const Map = () => {
  const mapContainerRef = useRef(null);
  const [points, setPoints] = useState([]);
  const [showAddInfoModal, setShowAddInfoModal] = useState(false);
  const [selectedPointId, setSelectedPointId] = useState(null);
  const [userLocation, setUserLocation] = useState([6.207262, 52.135714]); // Default coordinates

  useEffect(() => {
    const fetchCSVData = async () => {
      try {
        const response = await fetch("/points.csv"); // Ensure points.csv is accessible in the public folder
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const formattedData = result.data.map((row) => ({
              id: row.id,
              title: row.title,
              coordinates: { x: parseFloat(row.x), y: parseFloat(row.y) },
              image_url: row.image_url,
              text: row.text,
              website: row.website,
              marker_image: row.marker_image,
            }));
            setPoints(formattedData); // Update state with parsed points
          },
        });
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      }
    };

    fetchCSVData(); // Call the fetchCSVData function when the component mounts
  }, []);

  // Initialize map when component mounts
  useEffect(() => {
    // Check for user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([longitude, latitude]); // Set user location
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        { enableHighAccuracy: true }
      );
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/ekkel/cj96wykqm0xe12rqe23dbnne7",
      center: userLocation, // Use the user’s location as center
      zoom: 10,
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-left");

    // Function to handle Add Info button click
    function openAddInfoModal(pointId) {
      setSelectedPointId(pointId);
      setShowAddInfoModal(true);
    }

    // Add markers for each point
    points.forEach((point) => {
      // Check if coordinates are present
      if (point.coordinates) {
        const { x, y } = point.coordinates;

        // Create a custom marker element and set background image from public folder
        const customMarkerEl = document.createElement("div");
        customMarkerEl.className = "custom-marker";
        customMarkerEl.style.backgroundImage = `url(/${point.marker_image})`;
        customMarkerEl.style.width = "20px"; // Customize marker size if needed
        customMarkerEl.style.height = "28px";
        customMarkerEl.style.backgroundSize = "cover";

        const marker = new mapboxgl.Marker(customMarkerEl).setLngLat([x, y]);

        // Create popup content
        const popupContent = `
          <div class="popup-content">
          ${point.image_url ? `<img class="popup-image" src="${point.image_url}" alt="Image" />` : ''}
            <h3 style="font-size: 1.5em; font-weight: bold;">${point.title}</h3>
            <div class="popup-scroll-container">
              <div class="popup-text">
                ${point.text}
              </div>
            </div>
            ${point.website ? `<a href="${point.website}" target="_blank" rel="noopener noreferrer">Website</a>` : ''}
          </div>
        `;
        //<button class="popup-add-info-btn">Voeg melding toe</button>
        
        // Create popup
        const popup = new mapboxgl.Popup().setHTML(popupContent);

        // Attach popup to marker
        marker.setPopup(popup);

        popup.on("open", () => {
          const popupElement = document.querySelector(".mapboxgl-popup-content");
          if (popupElement) {
            popupElement.addEventListener("click", (event) => {
              if (event.target.classList.contains("popup-add-info-btn")) {
                openAddInfoModal(point.point_id); // Call the function with the correct argument
              }
            });
          }
        });

        // Add marker to map
        marker.addTo(map);
      } else {
        console.error("Coordinates are null for point:", point);
      }
    });
          
      return () => map.remove();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [points]);

      return (
        <div className="map-container" ref={mapContainerRef}>
          {showAddInfoModal && <AddInfoModal pointId={selectedPointId} onClose={() => setShowAddInfoModal(false)} />}
        </div>
      );
    };
    
    export default Map;