// Knmi.js
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const Knmi = () => {

  useEffect(() => {

    // Track page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Apply styles directly to the body for scrolling
    document.body.style.overflowY = 'auto';
    document.body.style.maxHeight = '100vh';

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.overflowY = 'initial';
      document.body.style.maxHeight = 'initial';
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="knmi-container" style={{ marginLeft: '190px', marginRight: '200px' }}>
    <h1>De KNMI IJspluim</h1>

    <h2>IJsgroeimodel</h2>
    <p>Het ijsgroeimodel berekent de watertemperatuur of (als de temperatuur onder nul komt) de ijsdikte van een laag 
    stilstaand water van twee meter diep. Het model houdt rekening met de invloed van de temperatuur en met de afkoeling 
    door de wind en verdamping. Ook de invloed van straling wordt in de modelberekeningen meegenomen. 
    Straling zorgt overdag doorgaans voor opwarming, maar 's nachts kan uitstraling bij heldere hemel voor afkoeling zorgen. 
    Al deze effecten worden in de berekeningen meegenomen.</p>

    {/* Display the image */}
        <img
          src="http://cdn.knmi.nl/knmi/map/page/weer/waarschuwingen_verwachtingen/extra/PLUIM_06260_IJS.png"
          alt="KNMI Pluim"
          width="700"
          height="700"
        />
    <p></p>
  </div>
  );
};

export default Knmi;