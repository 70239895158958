import React from 'react';

const GoGoogle = () => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
    <a href="https://play.google.com/store/apps/details?id=nu.bldr.ijsmeester" target="_blank" rel="noopener noreferrer">
    <img
        style={{
          position: 'absolute',
          top: 60,
          right: 10,
          border: 0,
          zIndex: 10,
          width: '160px', // Set the width of the image
          height: 'auto' // Automatically adjust the height to maintain aspect ratio
        }}
        alt="Google Play Store"
        src={process.env.PUBLIC_URL + '/google.png'}
      />
    </a>
  </div>
);

export default GoGoogle;