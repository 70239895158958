import React, { useState } from 'react';
import axios from 'axios';

const AddInfoModal = ({ pointId, onClose }) => {
  const [infoText, setInfoText] = useState('');

  // Update infoText state
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Limit input to 255 characters
    if (value.length <= 255) {
      setInfoText(value);
    }
  };

  console.error("AddInfoModal is called with PointId / Text = ", pointId, infoText);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send POST request to add information
      await axios.post('http://localhost:5001/api/add-info', { pointId, infoText });
      alert('Information added successfully');
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error adding information:', error);
      alert('Error adding information. Please try again.');
    }
  };

  const handleClose = () => {
    // Add logic here to hide the modal or perform any other necessary action
    onClose(); // Close the modal
  };

  return (
    <div className="add-info-modal">
      <h2>Voeg een nieuwe opmerking toe.</h2>
      <textarea
        value={infoText}
        onChange={handleInputChange}
        placeholder="Hier je text. Maximaal 255 tekens."
      />
      <div className="button-container">
        <p>{infoText.length}/255 tekens</p>
        <button onClick={handleSubmit}>Voeg toe</button>
        <button onClick={handleClose}>Terug</button>
    </div>
    </div>
  );
};

export default AddInfoModal;